










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class cAppBackground extends Vue {
  @Prop(String) image!: string;

  get url() {
    return `url("${this.image}")`;
  }
}
