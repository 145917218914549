








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class cColumn extends Vue {
  @Prop() gutter!: string;
  @Prop() xs!: string;
  @Prop() sm!: string;
  @Prop() md!: string;
  @Prop() lg!: string;

  get columnClass() {
    return [
      ...this.gutter ? [ `c-column--gutter-${this.gutter}` ] : [],
      this.columnPhone,
      this.columnTablet,
      this.columnSmallDesktop,
      this.columnLargeDesktop,
    ]
      .filter(c => c)
      .join(' ');
  }

  get columnPhone() {
    return this.xs ? `column-phone-${this.xs}` : null;
  }

  get columnTablet() {
    const value = [ this.sm, this.xs ].find(f => !!f);
    return value ? `column-tablet-${[ this.sm, this.xs ].find(f => !!f)}` : null;
  }

  get columnSmallDesktop() {
    const value = [ this.md, this.sm, this.xs ].find(f => !!f);
    return value ? `column-desktop-small-${value}` : null;
  }

  get columnLargeDesktop() {
    const value = [ this.lg, this.md, this.sm, this.xs ].find(f => !!f);
    return value ? `column-desktop-large-${value}` : null;
  }
}
