import '@yzfe/vue-svgicon-polyfill';
import Vue             from 'vue';
import axios           from 'axios';
import smoothscroll    from 'smoothscroll-polyfill';
import * as MessageBox from 'element-ui/lib/message-box.js';
import VueSvgIcon      from 'vue-svgicon';
import 'vue-svgicon/dist/svgicon.css';
import App             from './App.vue';
import './classComponentHooks';
import router          from './pages/router';
import store           from './store/store';
import './registerServiceWorker';
import lang            from 'element-ui/lib/locale/lang/pl';
import locale          from 'element-ui/lib/locale';
import { MODULE_PATH } from '@/pages/static/information/company/company.variables';
import VueCompositionApi from '@vue/composition-api';

require('promise.prototype.finally').shim();
require('smooth-scroll/dist/smooth-scroll.polyfills.js');

if ('NodeList' in window && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[ i ], i, this);
    }
  };
}

// kick off the polyfill!
smoothscroll.polyfill();

// configure language
locale.use(lang);

Vue.use(VueCompositionApi);

Vue.use(VueSvgIcon, {
  tagName: 'icon',
});

Vue.prototype.$http = axios;
Vue.prototype.$confirm = MessageBox.default.confirm;
Vue.prototype.$alert = MessageBox.default.alert;
Vue.config.productionTip = false;

const TYPE = {
  REDIRECT: 'redirect',
  STATUS: 'status',
  VALIDATION: 'validation',
};

const CODE = {
  OK: 'OK',
  WARN: 'WARN',
  ERROR: 'ERROR',
};

function getStatus(type: string, code: string) {
  if (code === CODE.OK) {
    return 'OK';
  }
  if (code === CODE.WARN) {
    return 'WARN';
  }

  return 'ERROR';
}

function addNotification(data: any) {
  store.dispatch('addNotification', {
    message: data.message,
    status: getStatus(data.type, data.code),
    errors: data.errors,
  });
}

function success(response: any): any {
  if (response.data.message) {
    addNotification(response.data);
  }

  if (response.data && response.data.main && response.data.main.title && response.data.global) {
    response.data.global.title = response.data.main.title;
  }

  if (response.data && response.data.type === TYPE.REDIRECT) {
    if (response.data.method && response.data.method === 'POST') {
      axios.post(response.data.location);
    } else if (response.data.location === router.currentRoute.fullPath && response.data.location === '/') {
      store.dispatch(`${MODULE_PATH}/loadPage`);
    } else {
      router.push(response.data.location);
    }

    // Set as error when is redirect
    response.status = 301;
    response.statusText = 'REDIRECT';
    response.data.error = response.data.error || {};

    throw { response };
  }

  return response;
}

function error(error: any) {
  if (error.response && error.response.data && error.response.data.message) {
    const params = new URL(error.request.responseURL);

    if (!(params.searchParams.get('skipNotification') === 'true')) {
      addNotification(error.response.data);
    }
  }
  if (error.response.data && error.response.data.type === TYPE.REDIRECT && error.response.data.location !== router.currentRoute.path) {
    if (error.response.data.method && error.response.data.method === 'POST') {
      axios.post(error.response.data.location);
    } else {
      router.push(error.response.data.location);
    }
  }

  return Promise.reject(error);
}

axios.interceptors.response.use(success, error);

Date.prototype.toLocalISOString = function (): string {
  // ISO8601 in local time zone

  const d = new Date(this);
  const pad = function (n: any) {
    return n < 10 ? `0${n}` : n;
  };
  const tz = d.getTimezoneOffset(); // mins
  let tzs = (tz > 0 ? '-' : '+') + pad(parseInt(String(Math.abs(tz / 60))));

  if (tz % 60 != 0) tzs += pad(Math.abs(tz % 60));

  if (tz === 0) // Zulu time == UTC
  {
    tzs = 'Z';
  }

  return `${d.getFullYear()}-${
    pad(d.getMonth() + 1)}-${
    pad(d.getDate())}T${
    pad(d.getHours())}:${
    pad(d.getMinutes())}:${
    pad(d.getSeconds())}${(tzs !== 'Z') ? `${tzs}:00` : tzs}`;
};

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
