import { RouteConfig } from 'vue-router/types/router';

export interface Route {
  path: string,
  name: string
}

const UserListPage = () => import(/* webpackChunkName: "chunks/admin" */ './user-list/user-list.page.vue');
const CompanyListPage = () => import(/* webpackChunkName: "chunks/admin" */ './company-list/company-list.page.vue');
const HostessListPage = () => import(/* webpackChunkName: "chunks/admin" */ './hostess-list/hostess-list.page.vue');
const OrderListPage = () => import(/* webpackChunkName: "chunks/admin" */ './order-list/order-list.page.vue');
const DashboardPage = () => import(/* webpackChunkName: "chunks/admin" */ './dashboard/dashboard.page.vue');


export const MODULE = '[ADMIN]';


export const USER_LIST: Route = { path: '/panel/admin/uzytkownicy', name: `${MODULE} userList` };
export const COMPANY_LIST: Route = { path: '/panel/admin/firmy', name: `${MODULE} companyList` };
export const HOSTESS_LIST: Route = { path: '/panel/admin/hostessy', name: `${MODULE} hostessList` };
export const ORDER_LIST: Route = { path: '/panel/admin/zlecenia', name: `${MODULE} orderList` };
export const DASHBOARD: Route = { path: '/panel/admin/dashboard', name: `${MODULE} dashboard` };

export const routes: RouteConfig[] = [
  { ...USER_LIST, component: UserListPage },
  { ...COMPANY_LIST, component: CompanyListPage },
  { ...HOSTESS_LIST, component: HostessListPage },
  { ...ORDER_LIST, component: OrderListPage },
  { ...DASHBOARD, component: DashboardPage },
];
