import { RouteConfig } from 'vue-router/types/router';

export interface Route {
  path: string,
  name: string
}

const SettingsPage = () => import(/* webpackChunkName: "chunks/company/settings" */ './dashboard/settings/settings.page.vue');
const OrderListPage = () => import(/* webpackChunkName: "chunks/company/order-list" */ './dashboard/order-list/order-list.page.vue');
const OrderDetailsPage = () => import(/* webpackChunkName: "chunks/company/order-detail" */ './dashboard/order-detail/order-detail.page.vue');
const OrderRatingPage = () => import(/* webpackChunkName: "chunks/company/order-rating" */ './dashboard/order-rating/order-rating.page.vue');
const UserProfilePage = () => import(/* webpackChunkName: "chunks/company/profile" */ './dashboard/profile/profile.page.vue');
const PaymentHistoryPage = () => import(/* webpackChunkName: "chunks/company/payment-history" */ './dashboard/payment-history/payment-history.page.vue');

const IntroductionPage = () => import(/* webpackChunkName: "chunks/company/introduction" */ './introduction/introduction.page.vue');
const IntroductionStep1BasicDataPage = () => import(/* webpackChunkName: "chunks/company/profile-step-1" */ './introduction/profile-step-1/profile-step-1.page.vue');
const IntroductionStep2SummaryPage = () => import(/* webpackChunkName: "chunks/company/profile-step-2" */ './introduction/summary-step-2/summary-step-2.page.vue');

const OrderCreatorPage = () => import(/* webpackChunkName: "chunks/company/order-creator" */ './dashboard/order-creator/order-creator.page.vue');
const OrderCreatorNewPage = () => import(/* webpackChunkName: "chunks/company/order-creator/step-1" */ './dashboard/order-creator/new-order/new-order.page.vue');
const OrderCreatorStep1DetailsPage = () => import(/* webpackChunkName: "chunks/company/order-creator/step-1" */ './dashboard/order-creator/general-step-1/general-step-1.page.vue');
const OrderCreatorStep2HoursPage = () => import(/* webpackChunkName: "chunks/company/order-creator/step-2" */ './dashboard/order-creator/hours-step-2/hours-step-2.page.vue');
const OrderCreatorStep3ChooseHostessPage = () => import(/* webpackChunkName: "chunks/company/order-creator/step-3" */ '@/pages/company/dashboard/order-creator/hostess-selection-step-3/hostess-selection-step-3.page.vue');
const ChooseHostessPage = () => import(/* webpackChunkName: "chunks/company/order-creator/step-3b" */ '@/pages/company/dashboard/order-creator/hostess-search-step-3b/hostess-search-step-3b.page.vue');
const OrderCreatorStep4PaymentPage = () => import(/* webpackChunkName: "chunks/company/order-creator/step-4" */ '@/pages/company/dashboard/order-creator/payment-step-4/payment-step-4.page.vue');


export const MODULE = '[COMPANY]';


export const ORDER_CREATOR: Route = { path: '/panel/firma/zlecenie', name: `${MODULE} orderCreator` };
export const ORDER_CREATOR_NEW: Route = { path: 'nowe', name: `${MODULE} orderCreatorNew` };
export const ORDER_CREATOR_STEP_1_DETAILS: Route = { path: ':id/1', name: `${MODULE} orderCreatorStep1Details` };
export const ORDER_CREATOR_STEP_2_HOURS: Route = { path: ':id/2', name: `${MODULE} orderCreatorStep2Hours` };
export const ORDER_CREATOR_STEP_3_CHOOSE_HOSTESS: Route = { path: ':id/3', name: `${MODULE} orderCreatorStep3ChooseHostess` };
export const CHOOSE_HOSTESS: Route = { path: ':id/3/szukaj', name: `${MODULE} chooseHostess` };
export const ORDER_CREATOR_STEP_4_PAYMENT: Route = { path: ':id/4', name: `${MODULE} orderCreatorStep4Payment` };

export const PAYMENT_HISTORY: Route = { path: '/panel/firma/platnosci', name: `${MODULE} paymentHistory` };
export const SETTINGS: Route = { path: '/panel/firma/ustawienia', name: `${MODULE} settings` };
export const ORDER_LIST: Route = { path: '/panel/firma/zlecenia', name: `${MODULE} orderList` };
export const ORDER_DETAILS: Route = { path: '/panel/firma/zlecenie/:id', name: `${MODULE} orderDetails` };
export const ORDER_RATING: Route = { path: '/panel/firma/zlecenie/:id/ocen', name: `${MODULE} orderRating` };
export const USER_PROFILE: Route = { path: '/panel/firma/profil', name: `${MODULE} userProfile` };

export const INTRODUCTION: Route = { path: '/panel/firma/wprowadzenie', name: `${MODULE} introduction` };
export const INTRODUCTION_STEP_1_BASIC_DATA: Route = { path: '1', name: `${MODULE} introductionStep1BasicData` };
export const INTRODUCTION_STEP_2_SUMMARY: Route = { path: '2', name: `${MODULE} introductionStep2Summary` };


export const routes: RouteConfig[] = [
  {
    ...INTRODUCTION,
    component: IntroductionPage,
    children: [
      {
        ...INTRODUCTION_STEP_1_BASIC_DATA,
        component: IntroductionStep1BasicDataPage,
      },
      {
        ...INTRODUCTION_STEP_2_SUMMARY,
        component: IntroductionStep2SummaryPage,
      },
    ],
  },

  { ...PAYMENT_HISTORY, component: PaymentHistoryPage },
  {
    ...ORDER_CREATOR,
    component: OrderCreatorPage,
    children: [
      {
        ...ORDER_CREATOR_NEW,
        component: OrderCreatorNewPage,
      },
      {
        ...ORDER_CREATOR_STEP_1_DETAILS,
        component: OrderCreatorStep1DetailsPage,
      },
      {
        ...ORDER_CREATOR_STEP_2_HOURS,
        component: OrderCreatorStep2HoursPage,
      },
      {
        ...ORDER_CREATOR_STEP_3_CHOOSE_HOSTESS,
        component: OrderCreatorStep3ChooseHostessPage,
      },
      { ...CHOOSE_HOSTESS, component: ChooseHostessPage },
      {
        ...ORDER_CREATOR_STEP_4_PAYMENT,
        component: OrderCreatorStep4PaymentPage,
      },
    ],
  },

  { ...SETTINGS, component: SettingsPage },
  { ...ORDER_LIST, component: OrderListPage },
  { ...ORDER_RATING, component: OrderRatingPage },
  { ...ORDER_DETAILS, component: OrderDetailsPage },
  { ...USER_PROFILE, component: UserProfilePage },
];
