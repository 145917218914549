import Vue    from 'vue';
import Router from 'vue-router';
import Store  from '@/store/store';

import { routes as staticRoutes }  from './static/static.router';
import { routes as companyRoutes } from './company/company.router';
import { routes as hostessRoutes } from './hostess/hostess.router';
import { routes as adminRoutes }   from './admin/admin.router';

function hasQueryParams(route: any) {
  return !!Object.keys(route.query).length;
}

Vue.use(Router);

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    return {
      x: 0,
      y: 0,
    };
  },
});

router.addRoutes(staticRoutes);
router.addRoutes(companyRoutes);
router.addRoutes(hostessRoutes);
router.addRoutes(adminRoutes);

router.onError(() => {
  Store.commit('setPageLoading', false);
});

router.beforeEach((to: any, from: any, next: any) => {
  Store.commit('setPageLoading', true);

  if (to.name === from.name) {
    next({ name: to.name, query: from.query, replace: true });
  }

  if (!hasQueryParams(to) && hasQueryParams(from) && [ 'login', 'register' ].includes(to.name)) {
    next({ name: to.name, query: from.query });
  } else {
    next();
  }
});

const queue: object[] = [];
let windowLoaded: boolean = false;

window.addEventListener('load', () => {
  windowLoaded = true;

  queue.forEach((layer: object) => {
    (window as any).dataLayer.push(layer);
  });
});

router.afterEach((to: any, from: any) => {
  Store.commit('setPageLoading', false);

  let event = null;

  if (Store.state.global.loggedIn) {
    event = {
      event: 'pageview',
      path: to.path,
      loggedIn: true,
      userEmail: Store.state.header.userProfile.email,
      userId: Store.state.header.userProfile.userId,
      userType: Store.state.header.userProfile.userType,
      userCompany: Store.state.header.userProfile.companyName,
      url: `${window.location.origin}${to.fullPath}`,
    };
  } else {
    event = {
      event: 'pageview',
      path: to.path,
      loggedIn: false,
      userEmail: undefined,
      userId: undefined,
      userType: undefined,
      userCompany: undefined,
      url: `${window.location.origin}${to.fullPath}`,
    };
  }

  if (windowLoaded) {
    (window as any).dataLayer.push(event);
  } else {
    queue.push(event);
  }
});

router.onReady((to: any, from: any) => {
  Store.commit('setRouterReady', true);
});

export default router;
