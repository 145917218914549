<template>
  <div v-show="notifications.length > 0"
       class="notifications">

    <single-notification v-for="notification in notifications"
                         :key="notification.id"
                         :notification="notification"
                         class="notifications__notification"></single-notification>
  </div>
</template>

<script>
import SingleNotification from '@/components/notifications/NotificationsSectionItem.component.vue';

export default {
  name: 'Notifications',
  components: {
    SingleNotification,
  },
  computed: {
    notifications() {
      return this.$store.state.global.notifications;
    },
    flashMessages() {
      return this.$store.state.global.flashMessages;
    },
  },
  mounted() {
    if (this.flashMessages) {
      Object.keys(this.flashMessages).forEach((status) => {
        const content = {
          status: null,
          message: this.flashMessages[status],
        };

        if (status === 'INFO') {
          content.status = 'OK';
        } else if (status === 'WARN') {
          content.status = 'WARN';
        } else {
          content.status = 'ERROR';
        }

        this.$store.dispatch('addNotification', content);
      });
    }
  },
};
</script>

<style lang="scss"
       scoped>

.notifications {
  position: fixed;
  z-index: 11000;
  top: 10rem;
  right: 0;
}
</style>
