



























import { Component }   from 'vue-property-decorator';
import { State }       from 'vuex-class';
import cSocialIconList from '@/components/v2/app/social-icon-list.component.vue';
import GoogleAnalytic  from '@/common/google-analytics.mixin';
import { mixins }      from 'vue-class-component';

@Component({
  components: {
    cSocialIconList,
  },
})
export default class cAppFooter extends mixins(GoogleAnalytic) {
  @State(state => state.footer.navigation) navigation!: any;
  @State(state => state.footer.logo) logo!: any;
  @State(state => state.footer.copyrights) copyrights!: any;
  @State(state => state.global.hideFooter) hideFooter!: boolean;
  @State(state => state.global.theme) theme!: string;

  get appFooterClass() {
    if (this.theme === 'exposure') {
      return 'c-app-footer--blank';
    }

    if (this.theme === 'minimalism') {
      return 'c-app-footer--standard';
    }

    return null;
  }
}
