import Axios from 'axios';

export function saveProfile(data: any) {
  return Axios.put('/api/panel/firma/profil', data);
}

export function getCompany(nip: any) {
  return Axios.post('/api/panel/firma/regon', { nip });
}

export function createOrder(data: any) {
  return Axios.post('/api/panel/firma/zlecenie/nowe', data);
}

export function updateOrder(step: any, orderId: any, data: any) {
  return Axios.put(`/api/panel/firma/zlecenie/${orderId}/${step}`, data);
}

export function calculatePayment(data: any, cancelToken?: any) {
  return Axios.post('/api/panel/firma/oblicz_platnosc?skipNotification=true', Object.assign({
    hourWage: null,
    budget: null,
    slots: [],
  }, data), {
    cancelToken,
  });
}

export function calculateTime(data: any) {
  return Axios.post('/api/panel/firma/oblicz_czas', data);
}

export function calculateCount(orderId: string, data: any) {
  return Axios.post(`/api/policz/${orderId}`, data);
}

export function inviteHostess(path: string, hostessIdList: String[]) {
  return Axios.post(path, {
    automaticSelection: false,
    hostesses: hostessIdList,
  });
}

export function orderPayment(path: string): Promise<any> {
  return Axios.post(path);
}

export function filterInSearch(data: any, orderId: any, emptyArray?: boolean) {
  let languages = '';
  if (emptyArray) {
    languages = '?requiredLanguages[]';
  }
  return Axios.get(`/api/filtruj/${orderId}${languages}`, { params: data });
}

export function filterAnonymousInSearch(data: any) {
  const search = (window.location.search) ? window.location.search : '';

  return Axios.get(`/api/filtruj${search}`, { params: data });
}

export function getRate() {

}

export function updateRate(orderId: string, hostessId: string, data: any) {
  return Axios.put(`/api/panel/firma/zlecenie/${orderId}/ocen/${hostessId}`, data);
}
