import Vue                             from 'vue';
import Vuex, { Module, ModuleOptions } from 'vuex';

import availability from '@/pages/hostess/__modules/week-calendar/week-calendar.store';
import form         from '@/store/modules/form.store';

Vue.use(Vuex);

declare global {
  interface Window {
    initData: any;
    initPath: string | null;
  }
}

function scroll() {
  store.commit('setStickyShadow', window.scrollY > 10);
}

const store = new Vuex.Store({
  strict: true,
  modules: {
    availability,
    form,
    static: {
      namespaced: true,
    },
    hostess: {
      namespaced: true,
    },
    company: {
      namespaced: true,
      modules: {
        orderCreator: {
          namespaced: true,
        },
      },
    },
    admin: {
      namespaced: true,
    },
  },
  state: Object.assign({
    pageLoading: false,
    routerReady: false,
    isGlobalLoaded: false,
    pageTypeText: '',
    pageType: '',
    stickyHeader: false,
    stickyShadow: false,
    header: {
      navigation: [],
    },
    main: {
      header: {
        logo: {},
      },
    },
    global: {
      theme: null,
      navigationPosition: null,
      bigNavigation: false,
      notifications: [],
      hideFooter: false,
      flashMessages: {},
    },
  }, window.initData),
  getters: {},
  mutations: {
    updateGlobal(state: any, data) {
      state.isGlobalLoaded = true;
      const { theme } = state.global;
      const { navigationPosition } = state.global;
      const { bigNavigation } = state.global;
      const { flashMessages } = state.global;
      const { notifications } = state.global;
      const { hideFooter } = state.global;
      const s = state;
      Object.assign(s, data);
      s.pageTypeText = data.pageTypeText || '';
      s.pageType = data.pageType;
      s.global.theme = theme;
      s.global.bigNavigation = bigNavigation;
      s.global.notifications = notifications;
      s.global.hideFooter = hideFooter;
      // s.global.navigationPosition = navigationPosition;
      s.global.flashMessages = flashMessages;

      if (data.global && data.global.title) {
        document.title = data.global.title;
      }
    },
    setPageLoading(state: any, payload: boolean) {
      // if (payload) {
      //   pageLoadingCouner++;
      // } else {
      //   pageLoadingCouner--;
      // }

      // state.pageLoading = pageLoadingCouner > 0;
      state.pageLoading = payload;
    },
    setRouterReady(state: any, payload: boolean) {
      state.routerReady = payload;
    },
    setTheme(state: any, data) {
      let s = state;
      s.global.theme = data;
      s = Object.assign({}, state.global);
    },
    setHideFooter(state: any, data) {
      state.global.hideFooter = data;
      state.global = Object.assign({}, state.global);
    },
    setStickyHeader(state: any, payload: boolean) {
      state.stickyHeader = payload;
    },
    setStickyShadow(state: any, payload: boolean) {
      state.stickyShadow = payload;
    },
    setBigNavigation(state: any, data) {
      const s = state;
      s.global.bigNavigation = data;
      s.global = Object.assign({}, s.global);
    },
    setNavigationPosition(state: any, data) {
      const s = state;
      s.global.navigationPosition = data;
      s.global = Object.assign({}, state.global);
    },
    addNotification(state: any, value) {
      const { length } = state.global.notifications;
      let id;

      if (length > 0) {
        id = state.global.notifications[length - 1].id + 1;
      } else {
        id = 0;
      }

      state.global.notifications.push(Object.assign(value, { id }));
    },
    removeNotification(state: any, value) {
      const index = state.global.notifications
        .findIndex((notification: any) => notification.id === value);

      state.global.notifications.splice(index, 1);
    },
  },
  actions: {
    updateGlobal: (context: any, value: any) => {
      context.commit('updateGlobal', value);

      if (value.main && value.main.form) {
        context.commit('form/setForm', value.main.form);
      }
    },
    setTheme: (context: any, value: any) => {
      context.commit('setTheme', value);
    },
    setHideFooter: (context: any, value: any) => {
      context.commit('setHideFooter', value);
    },
    setNavigationPosition: (context: any, value: any) => {
      context.commit('setNavigationPosition', value);
    },
    addNotification: (context: any, value: any) => {
      context.commit('addNotification', value);
    },
    removeNotification: (context: any, value: any) => {
      context.commit('removeNotification', value);
    },
    registerScrollListener(): void {
      window.addEventListener('scroll', scroll);
    },
    unregisterScrollListener(context: any): void {
      window.removeEventListener('scroll', scroll);
      context.commit('setStickyShadow', false);
    },
  },
});

export function registerStoreModule<T, S>(path: any, module: Module<T, S>, options?: ModuleOptions) {
  if (hasStoreModule(path) === false) {
    store.registerModule(path, module);
  }
}

export function hasStoreModule(path: any): boolean {
  /**
   * Todo: Change module system checking to store._module...
   */
  return hasObjectPath(store.state, (typeof path === 'string') ? path : path.join('.'));
}

export function hasObjectPath(variable: any, path: any) {
  const keys: string[] = ((typeof path === 'string') ? path : path.join('/')).split('.');

  for (const key of keys) {
    if (variable && variable.hasOwnProperty(key)) {
      variable = variable[key];
      continue;
    }

    return false;
  }

  return true;
}

export default store;

/**
 * Do not delete - view ResetPassword will stop working
 */
// delete window.initData;
