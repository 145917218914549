




















import Component     from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { State }     from 'vuex-class';

@Component
export default class cAppNavigation extends Vue {
  @Prop(Array) navigation!: any;
  @State(state => state.global.theme) theme!: any;

  iconSet = {
    'calendar-check-1-modified': require('@icon/calendar-check-1-modified.svg'),
    'window-list-user': require('@icon/window-list-user.svg'),
    'calendar-edit-2': require('@icon/calendar-edit-2.svg'),
    'piggy-bank': require('@icon/piggy-bank.svg'),
    'badge-star': require('@icon/badge-star.svg'),
  };

  get isVisibleNavigation() {
    return this.navigation.length > 0 && this.theme !== 'minimalism';
  }
}
