import { Component, Vue } from 'vue-property-decorator';

type CategoryEvent = 'micro' | 'unknown';
type ActionEvent = 'cta' | 'navigation' | 'call' | 'share' | 'chat' | 'send';

interface SendEventInterface {
  category: CategoryEvent,
  action: ActionEvent,
  label: string,
  value?: string,
}

@Component
export default class GoogleAnalytic extends Vue {
  categoryEvent = {
    MICRO: 'micro',
    UNKNOWN: 'unknown',
  };

  actionEvent = {
    CTA: 'cta',
    NAVIGATION: 'navigation',
    CALL: 'call',
    SHARE: 'share profile',
    CHAT: 'chat',
    SEND: 'send',
    HIRE_ME: 'hire me',
    COMPLETE_TEAM: 'complete team',
    RECEIVE_ORDERS: 'receive orders',
    CREATE_ORDER: 'create order',
  };

  private isFirstCharNotLetter(string: string | null): boolean {
    if (string == null) {
      return true;
    }

    const firstLetter = string[0];
    return /^a-zA-Z/.test(firstLetter);
  }

  private doNotStartsWithNull(string: string): boolean {
    return string.startsWith('null ') || string === 'null';
  }

  public sendAnalyticEvent({
    category = 'unknown',
    action,
    label,
    value,
  }: any): void {
    try {
      if (this.doNotStartsWithNull(label)) {
        return;
      }

      const dataLayer = (window as any).dataLayer;

      dataLayer.push({
        event: 'click',
        category,
        action,
        label,
        value,
      });
    } catch (e) {
      console.error('Cannot send analytic event.');
    }
  }
}
