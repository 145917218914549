import { RouteConfig } from 'vue-router/types/router';

export interface Route {
  path: string,
  name: string
}

const FinancePage = () => import(/* webpackChunkName: "chunks/hostess/personal-data" */ './dashboard/personal-questionnaire/personal-questionnaire.page.vue');
const appearancePage = () => import(/* webpackChunkName: "chunks/hostess/appearance" */ './dashboard/appearance/appearance.page.vue');
const PublicProfilePage = () => import(/* webpackChunkName: "chunks/hostess/public-profile" */ './public-profile/public-profile.page.vue');
const OrderDetailsPage = () => import(/* webpackChunkName: "chunks/hostess/order-detail" */ './dashboard/order-detail/order-detail.page.vue');
const OrderListPage = () => import(/* webpackChunkName: "chunks/hostess/order-list" */ './dashboard/order-list/order-list.page.vue');
const SchedulePage = () => import(/* webpackChunkName: "chunks/hostess/schedule" */ './dashboard/schedule/schedule.page.vue');
const SettingsPage = () => import(/* webpackChunkName: "chunks/hostess/settings" */ './dashboard/settings/settings.page.vue');
const OrderHunter = () => import(/* webpackChunkName: "chunks/hostess/orderHunter" */ './dashboard/order-hunter/order-hunter.page.vue');

const IntroductionPage = () => import(/* webpackChunkName: "chunks/hostess/introduction" */ './introduction/introduction.page.vue');
const IntroductionStep1BasicDataPage = () => import(/* webpackChunkName: "chunks/hostess/introduction/step-1" */ './introduction/appearance-step-1/appearance-step-1.page.vue');
const IntroductionStep2SchedulePage = () => import(/* webpackChunkName: "chunks/hostess/introduction/step-2" */ './introduction/schedule-step-2/schedule-step-2.page.vue');
const IntroductionStep3PaymentPage = () => import(/* webpackChunkName: "chunks/hostess/introduction/step-3" */ './introduction/personal-questionnaire-step-3/personal-questionnaire-step-3.page.vue');
const IntroductionStep4NotificationPage = () => import(/* webpackChunkName: "chunks/hostess/introduction/step-4" */ './introduction/notification-step-4/notification-step-4.page.vue');
const IntroductionStep5SummaryPage = () => import(/* webpackChunkName: "chunks/hostess/introduction/step-5" */ './introduction/summary-step-5/summary-step-5.page.vue');


export const MODULE = '[HOSTESS]';


export const FINANCE: Route = { path: '/panel/hostessa/finanse', name: `${MODULE} finance` };
export const PUBLIC_PROFILE: Route = { path: '/hostessa/:id', name: `${MODULE} publicProfile` };
export const ORDER_DETAILS: Route = { path: '/panel/hostessa/zlecenia/:orderId/pokaz', name: `${MODULE} orderDetails` };
export const ORDER_LIST: Route = { path: '/panel/hostessa/zlecenia', name: `${MODULE} orderList` };
export const USER_PROFILE: Route = { path: '/panel/hostessa/profil', name: `${MODULE} userProfile` };
export const SCHEDULE: Route = { path: '/panel/hostessa/grafik', name: `${MODULE} schedule` };
export const SETTINGS: Route = { path: '/panel/hostessa/ustawienia', name: `${MODULE} settings` };
export const HOSTESS_HUNTER: Route = { path: '/panel/hostessa/chce_zlecenie', name: `${MODULE} orderHunter` };

export const INTRODUCTION: Route = { path: '/panel/hostessa/wprowadzenie', name: `${MODULE} introduction` };
export const INTRODUCTION_STEP_1_BASIC_DATA: Route = { path: '1', name: `${MODULE} introductionStep1BasicData` };
export const INTRODUCTION_STEP_2_SCHEDULE: Route = { path: '2', name: `${MODULE} introductionStep2Schedule` };
export const INTRODUCTION_STEP_3_PAYMENT: Route = { path: '3', name: `${MODULE} introductionStep3Payment` };
export const INTRODUCTION_STEP_4_NOTIFICATION: Route = { path: '4', name: `${MODULE} introductionStep4Notification` };
export const INTRODUCTION_STEP_5_SUMMARY: Route = { path: '5', name: `${MODULE} introductionStep5Summary` };

export const routes: RouteConfig[] = [
  { ...FINANCE, component: FinancePage },
  { ...PUBLIC_PROFILE, component: PublicProfilePage },
  { ...ORDER_DETAILS, component: OrderDetailsPage },
  { ...ORDER_LIST, component: OrderListPage },
  { ...USER_PROFILE, component: appearancePage },
  { ...SCHEDULE, component: SchedulePage },
  { ...SETTINGS, component: SettingsPage },
  { ...HOSTESS_HUNTER, component: OrderHunter },

  {
    ...INTRODUCTION,
    component: IntroductionPage,
    children: [
      { ...INTRODUCTION_STEP_1_BASIC_DATA, component: IntroductionStep1BasicDataPage },
      { ...INTRODUCTION_STEP_2_SCHEDULE, component: IntroductionStep2SchedulePage },
      { ...INTRODUCTION_STEP_3_PAYMENT, component: IntroductionStep3PaymentPage },
      { ...INTRODUCTION_STEP_4_NOTIFICATION, component: IntroductionStep4NotificationPage },
      { ...INTRODUCTION_STEP_5_SUMMARY, component: IntroductionStep5SummaryPage },
    ],
  },
];
