






















import { Component, Vue } from 'vue-property-decorator';
import { State }          from 'vuex-class';

@Component
export default class cSocialIconList extends Vue {
  @State(state => state.footer.social) list!: any;
  @State(state => state.global.theme) theme!: string;

  iconSet = {
    'logo-facebook': require('@icon/logo-facebook.svg'),
    'logo-twitter-bird': require('@icon/logo-twitter-bird.svg'),
    'logo-youtube-clip': require('@icon/logo-youtube-clip.svg'),
    'logo-google-plus-2': require('@icon/logo-google-plus-2.svg'),
  };

  get appSocialIconListClass() {
    if (this.theme === 'exposure') {
      return 'c-social-icon-list--light';
    }

    return null;
  }

  socialIconClass(icon: string) {
    return `c-social-icon-list__icon--${icon}`;
  }
}
