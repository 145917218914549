




import Component from 'vue-class-component';

import { Vue }    from 'vue-property-decorator';
import { logout } from '@/services/user.service';
import { LOGIN }  from '../../static.router';
import router     from '../../../router';

@Component
export default class cLogout extends Vue {
  beforeRouteEnter(to: any, from: any, next: any) {
    logout()
      .catch(() => {
      })
      .finally(() => {
        next(new Error());
      });
  }
}
