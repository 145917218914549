


































import { Component, Vue }  from 'vue-property-decorator';
import { State }           from 'vuex-class';
import cAppLogo            from '@/components/v2/app/app-logo.component.vue';
import cAppUserCard        from '@/components/v2/app/app-user-card.component.vue';
import cAppShortNavigation from '@/components/v2/app/app-short-navigation.component.vue';
import cAppNavigation      from '@/components/v2/app/app-navigation.component.vue';
import { BREAKPOINTS }     from '@/global.variables';
import cAppLeftNavigation  from '@/components/v2/app/app-left-navigation.component.vue';
import cRow                from '@/components/v2/grid/row.component.vue';
import cColumn             from '@/components/v2/grid/column.component.vue';

@Component({
  components: {
    cAppLogo,
    cAppUserCard,
    cAppShortNavigation,
    cAppNavigation,
    cAppLeftNavigation,
    cRow,
    cColumn,
  },
})
export default class cAppHeader extends Vue {
  @State(state => state.main) main!: boolean;
  @State(state => state.stickyHeader) stickyHeader!: boolean;
  @State(state => state.stickyShadow) stickyShadow!: boolean;
  @State(state => state.global.loggedIn) loggedIn!: boolean;
  @State(state => state.header.navigation) navigation!: any;
  @State(state => state.global.navigationPosition) navigationPosition!: any;
  @State(state => state.global.theme) theme!: any;
  @State(state => state.isGlobalLoaded) isGlobalLoaded!: any;
  public mobileMode: boolean = false;

  get visibleNavigation() {
    return !this.loggedIn || this.navigationPosition !== 'TOP';
  }

  get isVisibleShortNavigation() {
    return this.navigation.length > 0 && [ 'exposure', 'minimalism' ].includes(this.theme);
  }

  get dynamicClass(): string | null {
    if (this.stickyHeader) {
      return 'c-app--sticky-header';
    }

    return null;
  }

  get appHeaderClass() {
    let classes: string[] = [];

    if ([ 'exposure', 'minimalism' ].includes(this.theme)) {
      classes.push('c-app-header--blank');
    }

    if (this.stickyHeader) {
      classes.push('c-app-header--sticky-header');
    }

    if (this.stickyShadow) {
      classes.push('c-app-header--with-shadow');
    }

    return classes;
  }

  public checkIsMobile(): void {
    this.mobileMode = window.innerWidth <= BREAKPOINTS.TABLET.MAX;
  }

  public mounted(): void {
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  }

  public destroyed(): void {
    window.removeEventListener('resize', this.checkIsMobile);
  }
}
