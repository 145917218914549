
























import { Component, Watch } from 'vue-property-decorator';
import { State }            from 'vuex-class';
import { mixins }           from 'vue-class-component';
import cAppHeader           from '@/components/v2/app/app-header.component.vue';
import cAppNavigation       from '@/components/v2/app/app-navigation.component.vue';
import cAppFooter           from '@/components/v2/app/app-footer.component.vue';
import cAppBackground       from '@/components/v2/app/app-background.component.vue';
import Notifications        from '@/components/notifications/NotificationsSection.component.vue';
import LoadingScreen        from '@/components/v2/loading-screen.component.vue';
import GoogleAnalytic       from '@/common/google-analytics.mixin';

@Component({
  components: {
    cAppFooter,
    cAppBackground,
    Notifications,
    LoadingScreen,
    cAppNavigation,
    cAppHeader,
  },
})
export default class cApp extends mixins(GoogleAnalytic) {
  @State(state => state.stickyHeader) stickyHeader!: boolean;
  @State(state => state.pageLoading) progress!: any;
  @State(state => state.header.navigation) navigation!: any;
  @State(state => state.global.navigationPosition) navigationPosition!: any;
  @State(state => state.isGlobalLoaded) isGlobalLoaded!: any;
  @State(state => state.global.theme) theme!: any;

  get visibleBackground() {
    return [ 'exposure' ].includes(this.theme);
  }

  get dynamicClass(): string | null {
    if (this.stickyHeader) {
      return 'c-app--sticky-header';
    }

    return null;
  }

  // public mounted(): void {
  //   if ((window as any).HubSpotConversations) {
  //     this.addEventOnChatStart();
  //   } else {
  //     (window as any).hsConversationsOnReady = [
  //       this.addEventOnChatStart,
  //     ];
  //   }
  // }
  //
  // addEventOnChatStart(): void {
  //   const chat: HTMLElement | null = document.querySelector('#hubspot-messages-iframe-container button');
  //
  //   if (chat) {
  //     chat.addEventListener('click', () => {
  //       this.sendAnalyticEvent({
  //         category: this.categoryEvent.MICRO,
  //         action: this.actionEvent.CHAT,
  //       });
  //     });
  //   }
  // }

  @Watch('theme')
  themeWatch(value: string) {
    if (value === 'exposure') {
      document.body.classList.add('body--dark');
    } else {
      document.body.classList.remove('body--dark');
    }
  }

  @Watch('progress')
  progressWatch(value: boolean) {
    if (value) {
      document.body.classList.add('c-page-loading');
    } else {
      document.body.classList.remove('c-page-loading');
    }
  }
}
