







import { Component, Vue } from 'vue-property-decorator';
import { State }          from 'vuex-class';

@Component
export default class cLoadingScreen extends Vue {
  @State routerReady!: boolean;
}
