<template>
  <transition name="fade">
    <div @click="selfDestroy()"
         :class="statusClass"
         class="single-notification">

      <icon :data="statusIcon"
            class="single-notification__icon"></icon>

      <div class="single-notification__text">
        <span class="single-notification__status">{{ statusMessage }}</span>
        <span class="single-notification__message">{{ notification.message }}</span>

        <ul v-if="false && notification.errors"
            class="single-notification__errors">

          <li v-for="(field, key) in this.notification.errors"
              :key="key">

            <span v-text="key"></span>

            <ul>
              <li v-for="(error, index) in field"
                  :key="index"
                  v-text="error"></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script>
const DESTROY_DELAY = 3000;

export default {
  name: 'SingleNotification',
  props: {
    notification: Object,
  },
  computed: {
    getErrors() {
      return Object.keys(this.notification.errors).map(key => this.notification.errors[key][0]);
    },
    notifications() {
      return this.$store.state.global.notifications;
    },
    statusMessage() {
      if (this.notification.status === 'OK') {
        return 'Sukces';
      } if (this.notification.status === 'WARN') {
        return 'Ostrzeżenie';
      } if (this.notification.status === 'ERROR') {
        return 'Błąd';
      }

      return null;
    },
    statusIcon() {
      if (this.notification.status === 'OK') {
        return require('@icon/check-1.svg');
      } if (this.notification.status === 'WARN') {
        return require('@icon/location-pin-exclamation-2.svg');
      } if (this.notification.status === 'ERROR') {
        return require('@icon/close-shield.svg');
      }

      return null;
    },
    statusClass() {
      if (this.notification.status === 'OK') {
        return 'single-notification--ok';
      } if (this.notification.status === 'WARN') {
        return 'single-notification--warning';
      } if (this.notification.status === 'ERROR') {
        return 'single-notification--error';
      }

      return null;
    },
  },
  data() {
    return {
      timeout: null,
    };
  },
  methods: {
    setAutodestruction() {
      this.timeout = setTimeout(() => {
        this.selfDestroy();
      }, DESTROY_DELAY);
    },
    selfDestroy() {
      this.$store.dispatch('removeNotification', this.notification.id);
    },
  },
  mounted() {
    this.setAutodestruction(this.notification);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss"
       scoped>

$green: #56B130;
$warning: #e48b15;
$red: #DA4433;

.single-notification {
  background: $green;
  color: white;
  padding: 1.6rem;
  width: 32rem;
  margin-bottom: 1px;
  margin-right: 1px;
  cursor: pointer;
  transition: background-color .15s;
  display: flex;
  align-items: flex-start;
  border-radius: 3px;

  &.single-notification--ok {
    background: $green;

    /*.single-notification__icon {*/
    /*fill: green;*/
    /*}*/

    &:hover {
      background: darken($green, 5%);
    }
  }

  &.single-notification--warning {
    background: $warning;

    &:hover {
      background: darken($warning, 5%);
    }
  }

  &.single-notification--error {
    background: $red;

    /*.single-notification__icon {*/
    /*fill: #ed4a37;*/
    /*}*/

    &:hover {
      background: darken($red, 5%);
    }
  }
}

.single-notification__status {
  font-weight: bold;
  display: block;
  margin-bottom: .8rem;
}

.single-notification__icon {
  width: 3.2rem;
  height: 3.2rem;
  fill: white;
  margin-right: 1.6rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.single-notification__message {
  line-height: 1.3;
}

ul {
  color: rgba(255, 255, 255, 0.75);
  margin-top: .4rem;

  ul {
    list-style: disc;
    margin-left: 1.8rem;
  }
}

li {
  margin-top: .8rem;
  line-height: 1.2;
}

.single-notification__errors {
  margin-top: 1.2rem;
}
</style>
