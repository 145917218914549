











































import { Component, Vue, Prop } from 'vue-property-decorator';
import { State }          from 'vuex-class';

@Component
export default class cAppUserCardDropdown extends Vue {
  @State(state => state.header.navigation) headerNavigation!: any;
  @State(state => state.header.userProfile) userProfile!: any;
  @State(state => state.global.theme) theme!: any;
  @Prop(Boolean) mobileMode !: boolean;

  get navigation(): object[] {
    if (!this.userProfile) {
      return [];
    }

    return this.userProfile.navigation;
  }

  iconSet = {
    'calendar-check-1-modified': require('@icon/calendar-check-1-modified.svg'),
    'window-list-user': require('@icon/window-list-user.svg'),
    'calendar-edit-2': require('@icon/calendar-edit-2.svg'),
    'piggy-bank': require('@icon/piggy-bank.svg'),
    'badge-star': require('@icon/badge-star.svg'),
    'logout-2': require('@icon/logout-2.svg'),
    cog: require('@icon/cog.svg'),
    'window-application-4': require('@icon/window-application-4.svg'),
    'id-card-3': require('@icon/id-card-3.svg'),
  };

  get isVisibleShortNavigation() {
    return this.navigation.length > 0 && [ 'exposure', 'minimalism' ].includes(this.theme);
  }
}
