var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"c-app-left-navigation"},[_vm._l((_vm.navigation),function(link,index){return [(link.router && link.router.path && link.router.path.startsWith('tel:'))?_c('a',{key:("tel-" + index),staticClass:"c-app-left-navigation__link c-app-left-navigation__link--phone",attrs:{"href":link.router.path},on:{"click":function($event){return _vm.sendAnalyticEvent({
        category: _vm.categoryEvent.MICRO,
        action: _vm.actionEvent.CALL,
        label: link.label,
       })}}},[(link.icon)?_c('icon',{staticClass:"c-app-left-navigation__icon",attrs:{"data":_vm.iconSet[link.icon]}}):_vm._e(),_c('span',{staticClass:"c-app-left-navigation__text",domProps:{"textContent":_vm._s(link.text)}})],1):(link.router && link.router.path && link.router.path.startsWith('#'))?_c('a',{key:("ancor-" + index),staticClass:"c-app-left-navigation__link",attrs:{"href":link.router.path},on:{"click":function($event){[
         _vm.click(link.router, $event),
         _vm.sendAnalyticEvent({
          category: _vm.categoryEvent.MICRO,
          action: _vm.actionEvent.NAVIGATION,
          label: link.label,
         })
       ]}}},[(link.icon)?_c('icon',{staticClass:"c-app-left-navigation__icon",attrs:{"data":_vm.iconSet[link.icon]}}):_vm._e(),_c('span',{staticClass:"c-app-left-navigation__text",domProps:{"textContent":_vm._s(link.text)}})],1):_c('router-link',{key:("router-" + index),staticClass:"c-app-left-navigation__link",attrs:{"exact":true,"to":link.router},on:{"click":function($event){return _vm.sendAnalyticEvent({
                  category: _vm.categoryEvent.MICRO,
                  action: _vm.actionEvent.NAVIGATION,
                  label: link.label,
                 })}}},[(link.icon)?_c('icon',{staticClass:"c-app-left-navigation__icon",attrs:{"data":_vm.iconSet[link.icon]}}):_vm._e(),_c('span',{staticClass:"c-app-left-navigation__text",domProps:{"textContent":_vm._s(link.text)}})],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }