


















import { Component, Vue } from 'vue-property-decorator';
import { State }          from 'vuex-class';

@Component
export default class cAppLogo extends Vue {
  @State(state => state.header.logo.image) image!: any;
  @State(state => state.header.logo.router) router!: any;
  @State(state => state.global.theme) theme!: any;
  @State(state => state.pageTypeText) pageTypeText!: string;
  @State(state => state.pageType) pageType!: string;

  get logoUrl() {
    if (this.theme === 'minimalism') {
      return '/assets/images/logo.svg';
    }

    return '/assets/images/logo-white.svg';
  }
}
