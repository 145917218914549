import Axios from 'axios';

export function register(data: any, params: string = '') {
  if (!params && window.location.search) {
    params = `${window.location.search}`;
  }

  return Axios.post(`/api/zarejestruj${params}`, data);
}

export function activateAccount(data: any) {
  return Axios.post('/api/aktywacja_konta', data);
}

export function login(data: any, params: string = '') {
  if (!params && window.location.search) {
    params = `${window.location.search}`;
  }

  return Axios.post(`/api/zaloguj${params}`, data);
}

export function logout() {
  return Axios.get('/api/wyloguj');
}

export function remindPassword(data: any) {
  return Axios.post('/api/reset_hasla', data);
}

export function sendMessage(data: any) {
  return Axios.post('/api/kontakt', data);
}

export function newPassword(data: any, token: string) {
  return Axios.post(`/api/reset_hasla/${token}`, data);
}

export function qualifyHostess(data: any) {
  return Axios.post('/api/zapisy', data);
}

export function resendActivation(token: string) {
  return Axios.post(token);
}

export function search(data: string) {
  const search = (window.location.search) ? window.location.search : '';

  return Axios.get(`/api/szukaj${search}`, { params: data });
}

export function filter(data: string) {
  return Axios.get('/api/filtruj', { params: data });
}
