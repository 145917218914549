import { RouteConfig } from 'vue-router/types/router';


import LogoutPage from './information/logout/logout.page.vue';
// import HostessQualificationPage from './information/hostess-qualification/hostess-qualification.page.vue';
// import ChooseHostessPage        from '@/pages/company/chooseHostess/hostess-search-step-3b.page.vue';
// import ChooseHostessModalPage   from '@/pages/company/chooseHostess/ChooseHostess.modal.vue';

const DashboardPage = () => import(/* webpackChunkName: "chunks/static/dashboard" */ './information/dashboard/dashboard.page.vue');
const CompanyPage = () => import(/* webpackChunkName: "chunks/static/company" */ './information/company/company.page.vue');
const HostessPage = () => import(/* webpackChunkName: "chunks/static/hostess" */ './information/hostess/hostess.page.vue');
const HostessHirePage = () => import(/* webpackChunkName: "chunks/static/hostess" */ './information/hostess-hire/hostess-hire.page.vue');
const FAQPage = () => import(/* webpackChunkName: "chunks/static/faq" */ './information/faq/faq.page.vue');
const PrivacyPage = () => import(/* webpackChunkName: "chunks/static/privacy" */ './information/privacy/privacy.page.vue');
const RulesPage = () => import(/* webpackChunkName: "chunks/static/rules" */ './information/rules/rules.page.vue');
const ContactPage = () => import(/* webpackChunkName: "chunks/static/contact" */ './information/contact/contact.page.vue');
const LoginPage = () => import(/* webpackChunkName: "chunks/static/login" */ './account/login/login.page.vue');
const RegisterPage = () => import(/* webpackChunkName: "chunks/static/register" */ './account/register/register.page.vue');
const AccountActivationPage = () => import(/* webpackChunkName: "chunks/static/register" */ './account/account-activation/account-activation.page.vue');
const RemindPasswordPage = () => import(/* webpackChunkName: "chunks/static/remind-password" */ './account/remind-password/remind-password.page.vue');
const ResetPasswordPage = () => import(/* webpackChunkName: "chunks/static/reset-password" */ './account/reset-password/reset-password.page.vue');

export interface Route {
  path: string,
  name: string
}


export const MODULE = '[STATIC]';


export const HOSTESS: Route = { path: '/hostessa', name: `${MODULE} hostess` };
export const HOSTESS_HIRE: Route = { path: '/hostessa/zatrudnij/:id', name: `${MODULE} hostessHire` };
export const COMPANY: Route = { path: '/', name: `${MODULE} company` };
export const FAQ: Route = { path: '/faq', name: `${MODULE} faq` };
export const PRIVACY: Route = { path: '/polityka_prywatnosci', name: `${MODULE} privacy` };
export const RULES: Route = { path: '/regulamin', name: `${MODULE} rules` };
export const CONTACT: Route = { path: '/kontakt', name: `${MODULE} contact` };
export const ACCOUNT_ACTIVATION: Route = { path: '/aktywacja_konta', name: `${MODULE} accountActivation` };
export const LOGIN: Route = { path: '/zaloguj', name: `${MODULE} login` };
export const REGISTER: Route = { path: '/zarejestruj', name: `${MODULE} register` };
export const REMIND_PASSWORD: Route = { path: '/reset_hasla', name: `${MODULE} RemindPassword` };
export const RESET_PASSWORD: Route = { path: '/reset_hasla/:token', name: `${MODULE} resetPassword` };
export const LOGOUT: Route = { path: '/wyloguj', name: `${MODULE} logout` };

export const DASHBOARD: Route = { path: '/panel', name: `${MODULE} dashboard` };

export const routes: RouteConfig[] = [
  { ...COMPANY, component: CompanyPage },
  { ...FAQ, component: FAQPage },
  { ...PRIVACY, component: PrivacyPage },
  { ...RULES, component: RulesPage },
  { ...CONTACT, component: ContactPage },
  { ...LOGIN, component: LoginPage },
  { ...REGISTER, component: RegisterPage },
  { ...REMIND_PASSWORD, component: RemindPasswordPage },
  { ...RESET_PASSWORD, component: ResetPasswordPage },
  { ...ACCOUNT_ACTIVATION, component: AccountActivationPage },
  { ...HOSTESS, component: HostessPage },
  { ...LOGOUT, component: LogoutPage },
  { ...DASHBOARD, component: DashboardPage },
  { ...HOSTESS_HIRE, component: HostessHirePage },

];
