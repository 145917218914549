import { addDays }           from 'date-fns';
import { MONTHS, WEEK_DAYS } from './week-calendar.service';

const NOW = Date.now();

export default {
  namespaced: true,
  state() {
    return {
      today: NOW,
      week: [],
      date: addDays(NOW, 1),
      MONTHS,
      WEEK_DAYS,
    };
  },
  getters: {},
  mutations: {
    updateWeek(state: any, data: any) {
      const s = state;
      s.week = data;
    },
    changeDate(state: any, data: any) {
      const s = state;
      s.date = data;
    },
    updateSchedule(state: any, value: any) {
      (this as any).state.main.data = value;
    },
  },
  actions: {
    updateWeek: (context: any, value: any) => {
      context.commit('updateWeek', value);
    },
    changeDate: (context: any, value: any) => {
      context.commit('changeDate', value);
    },
    updateSchedule: (context: any, value: any) => {
      context.commit('updateSchedule', value);
    },
  },
};
