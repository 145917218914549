/* eslint-disable */

import Vue                 from 'vue';
import * as CompanyService from '@/services/company.service';

const goThroughAllFieldsAnd = () => {

};

export default {
  namespaced: true,
  state: {
    fields: [],
    hint: null,
    title: null,
    submit: null,
  },
  getters: {
    getElementByName: (state: any) => (name: any) => {
      let element = null;

      state.fields.find((f: any) => {
        if (f.name === name) {
          element = f;
          return true;
        } else if (f.fields) {
          f.fields.find((fi: any) => {
            if (fi.name === name) {
              element = fi;
              return true;
            }
          })
        }
      });

      return element;
    },
    getElement(e: any) {

    },
    prepareFieldsToSend(state: any) {
      return state.fields.reduce((output: any, field: any) => {
        if (field.fields) {
          field.fields.forEach((field2: any) => {
            Object.assign(output, { [field2.name]: field2.value });
          });


        } else {
          Object.assign(output, { [field.name]: field.value });
        }

        return output;
      }, {});
    },
    prepareFieldsToSendForFilterForm(state: any, getters: any, rootState: any) {
      if (!rootState.main || !rootState.main.filterForm) {
        return;
      }

      return rootState.main.filterForm.fields.reduce((output: any, field: any) => {
        if (field.fields) {
          field.fields.forEach((field2: any) => {
            Object.assign(output, { [field2.name]: field2.value });
          });


        } else {
          Object.assign(output, { [field.name]: field.value });
        }

        return output;
      }, {});
    },
  },
  mutations: {
    updateFilterForm(state: any, data: any) {
      // Object.assign((this as any).state.main.filterForm.fields[data.index], data.value);

      // goThroughAllFieldsAnd()
      (this as any).state.main.filterForm.fields.forEach((element: any) => {
        if (element === data.index) {
          Object.assign(element, data.value);
        } else if (element.fields) {
          element.fields.forEach((element2: any) => {
            if (element2 === data.index) {
              Object.assign(element2, data.value);
            }
          });
        }
      });

      (this as any).state.main.filterForm.fields = [...(this as any).state.main.filterForm.fields];
    },
    updateField(state: any, data: any) {
      // goThroughAllFieldsAnd()
      state.fields.forEach((element: any) => {
        if (element === data.index || element.name === data.index) {
          Object.assign(element, data.value);
        } else if (element.fields) {
          element.fields.forEach((element2: any) => {
            if (element2 === data.index || element2.name === data.index) {
              Object.assign(element2, data.value);
            }
          });
        }
      });

      (this as any).state.main.form.fields = [...(this as any).state.main.form.fields];
    },
    updateFieldByName({ fields }: any, { name, value }: any) {
      fields.forEach((element: any) => {
        if (element.name === name) {
          Object.assign(element, value);
        } else if (element.fields) {
          element.fields.forEach((element2: any) => {
            if (element2.name === name) {
              Object.assign(element2, value);
            }
          });
        }
      });

      (this as any).state.main.form.fields = [...(this as any).state.main.form.fields];
    },
    clearErrors({ fields }: any) {
      fields.forEach((f: any) => {
        if (!f.hasOwnProperty('fields')) {
          f.errors = [];
        } else if (f.fields) {
          f.fields.forEach((f2: any) => {
            if (!f2.hasOwnProperty('fields')) {
              f2.errors = [];
            }
          });
        }
      });

      (this as any).state.main.form.fields = [...(this as any).state.main.form.fields];
    },
    clearErrors2({ fields }: any) {
      (this as any).state.main.searchForm.fields.forEach((f: any) => {
        if (!f.hasOwnProperty('fields')) {
          f.errors = [];
        } else if (f.fields) {
          f.fields.forEach((f2: any) => {
            if (!f2.hasOwnProperty('fields')) {
              f2.errors = [];
            }
          });
        }
      });

      (this as any).state.main.searchForm.fields = [...(this as any).state.main.searchForm.fields];
    },
    updateField2(state: any, data: any) {
      // goThroughAllFieldsAnd()
      (this as any).state.main.searchForm.fields.forEach((element: any) => {
        if (element === data.index || element.name === data.index) {
          Object.assign(element, data.value);
        } else if (element.fields) {
          element.fields.forEach((element2: any) => {
            if (element2 === data.index || element2.name === data.index) {
              Object.assign(element2, data.value);
            }
          });
        }
      });

      (this as any).state.main.searchForm.fields = [...(this as any).state.main.searchForm.fields];
    },
    disableAllFields({ fields }: any) {
      fields.forEach((f: any) => {
        if (!f.hasOwnProperty('fields')) {
          Vue.set(f.options, 'DISABLED', true);
        } else if (f.fields) {
          f.fields.forEach((f2: any) => {
            if (!f2.hasOwnProperty('fields')) {
              Vue.set(f2.options, 'DISABLED', true);
            }
          });
        }
      });

      (this as any).state.main.form.fields = [...(this as any).state.main.form.fields];
    },
    enableAllFields({ fields }: any) {
      fields.forEach((f: any) => {
        if (!f.hasOwnProperty('fields')) {
          Vue.set(f.options, 'DISABLED', false);
        } else if (f.fields) {
          f.fields.forEach((f2: any) => {
            if (!f2.hasOwnProperty('fields')) {
              Vue.set(f2.options, 'DISABLED', false);
            }
          });
        }
      });

      (this as any).state.main.form.fields = [...(this as any).state.main.form.fields];
    },
    updatePasswordFormField(state: any, data: any) {
      Object.assign(data.index, data.value);
    },
    setForm(state: any, form: any) {
      state.fields = form.fields;
      state.hint = form.hint;
      state.title = form.title;
      state.submit = form.submit;
    },
    setTime(state: any, obj: any) {
      Vue.set(obj.group, 'totalTime', obj.time);
      Vue.set(obj.group, 'loadingTime', obj.loading);
      (this as any).state.main.form.fields = [...(this as any).state.main.form.fields];
    }
  },
  actions: {
    updateField: (context: any, value: any) => {
      context.commit('updateField', value);
    },
    updatePasswordFormField: (context: any, value: any) => {
      context.commit('updatePasswordFormField', value);
    },
    updateFilterForm: (context: any, value: any) => {
      context.commit('updateFilterForm', value);
    },
    calculateTime: ({ state, commit }: any, value: any) => {
      let group: any = null;

      state.fields
        .filter((fs: any) => fs.fields)
        .find((fs: any) => {
          const field = fs.fields.find((f: any) => f === value);
          if (field) {
            group = fs;
          }

          return field;
        });

      if (group) {
        // const date = group.find(f => f.id.contains('date'));
        const start = group.fields.find((f: any) => f.id.includes('start')).value;
        const finish = group.fields.find((f: any) => f.id.includes('finish')).value;

        if (start === '' || start.includes('_') ||
          finish === '' || finish.includes('_')) {

          commit('setTime', { group, time: '-', loading: false });
          return
        }

        commit('updateField', {
          index: value,
          value: {
            errors: []
          }
        });

        commit('setTime', { group, time: '-', loading: true });

        CompanyService.calculateTime({ start, finish })
          .then((response: any) => {
            // const format = response.data.split(':');
            // format[0] += 'h ';
            // format[1] += 'm ';
            commit('setTime', { group, time: response.data + ' h', loading: false });
          })
          .catch(error => {
            const errors = error.response.data.errors;

            commit('updateField', {
              index: value,
              value: {
                errors: errors[Object.keys(errors)[0]]
              }
            });

            commit('setTime', { group, time: '-', loading: false });
          })
      }
    }
  },
};
