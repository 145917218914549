





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class cAppShortNavigation extends Vue {
  @Prop(Array) navigation!: any;

  iconSet = {
    'calendar-check-1-modified': require('@icon/calendar-check-1-modified.svg'),
    'window-list-user': require('@icon/window-list-user.svg'),
    'calendar-edit-2': require('@icon/calendar-edit-2.svg'),
    'piggy-bank': require('@icon/piggy-bank.svg'),
    'badge-star': require('@icon/badge-star.svg'),
  };
}
