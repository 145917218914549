export const BREAKPOINTS = {
  PHONE: {
    MIN: 0,
    MAX: 599,
  },
  TABLET: {
    MIN: 600,
    MAX: 1023,
  },
  DESKTOP_SMALL: {
    MIN: 1024,
    MAX: 1499,
  },
  DESKTOP_LARGE: {
    MIN: 1500,
    MAX: Infinity,
  },
};
