








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class cRow extends Vue {
  @Prop({
    validator: value => [ 'left', 'center', 'right' ].includes(value),
  }) align!: string;

  @Prop({
    validator: value => [ 'small', 'normal' ].includes(value),
  }) size!: string;

  get alignRowClass() {
    if (this.align) {
      return `row--${this.align}`;
    }

    return null;
  }

  get gutterSizeClass() {
    if (this.size === 'small') {
      return 'c-row--small';
    }

    return null;
  }
}
