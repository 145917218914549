

































































import Component, { mixins } from 'vue-class-component';
import { Prop }              from 'vue-property-decorator';
import { State }             from 'vuex-class';
import GoogleAnalytic        from '@/common/google-analytics.mixin';

@Component
export default class cAppLeftNavigation extends mixins(GoogleAnalytic) {
  @Prop(Array) navigation!: any;
  @State(state => state.global.theme) theme!: any;

  iconSet = {
    'phone-5': require('@icon/phone-5.svg'),
  };

  get isVisibleNavigation() {
    return this.navigation.length > 0 && this.theme !== 'minimalism';
  }

  click(to: any, e: Event) {
    const SPACE: number = 60;

    if (to && to.path && to.path.startsWith('#')) {
      e.preventDefault();

      try {
        const HEADER_HEIGHT = (document.querySelector('.c-app-header') || {} as any).clientHeight || 0;
        const targetElement: HTMLElement | undefined = document.querySelector(to.path);

        window.scrollTo({
          top: window.pageYOffset + (targetElement as HTMLElement).getBoundingClientRect().top - HEADER_HEIGHT + 1 - SPACE,
          behavior: 'smooth',
        });
      } catch (e) {
        console.error('Not able to scroll to register form.');
      }
    }
  }
}
