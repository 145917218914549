




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State }                from 'vuex-class';
import cAppUserCardDropdown     from './app-user-card-dropdown.component.vue';
import clickOutside             from '@/components/clickOutside/clickOutside';

@Component({
  components: {
    cAppUserCardDropdown,
  },
  directives: {
    clickOutside,
  },
})
export default class cAppUserCard extends Vue {
  @State(state => state.header.navigation) navigation!: any;
  @State(state => state.header.userProfile.image) image !: any;
  @State(state => state.header.userProfile.name) name !: any;
  @State(state => state.global.theme) theme !: any;
  @State(state => state.global.loggedIn) loggedIn!: boolean;
  @Prop(Boolean) mobileMode !: boolean;
  isMenuOpen: boolean = false;

  get nameFirstLetter() {
    if (this.name.length > 0) {
      return this.name[ 0 ];
    }

    return '';
  }

  get appUserCardClass() {
    return {
      'c-app-user-card--menu-open': this.isMenuOpen,
      'c-app-user-card--light': [ 'minimalism', 'exposure' ].includes(this.theme),
    };
  }

  get photoStyles() {
    const { url } = this.image;

    if (!url) {
      return null;
    }

    return { 'background-image': `url('${url}')` };
  }

  closeMenu() {
    this.isMenuOpen = false;
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
