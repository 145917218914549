export const PAGE_NAME: string = 'Company';
export const MODULE_PATH: string = 'static/company';

export const MODULE_LOCALISATION: string[] = MODULE_PATH.split('/');

export const SECTION_TYPE = {
  BENEFIT: 'BENEFIT',
  TESTIMONIALS: 'TESTIMONIALS',
  PRICING: 'PRICING',
  FAQ: 'FAQ',
};
